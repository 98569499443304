import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import ServiceSubtitleComponent from '@/core/shared/service-subtitle/service-subtitle.component.vue';
import {container} from 'tsyringe';
import {ImeiCheckServiceSandbox} from '@/pages/imei-check-service/sandbox/imei-check-service.sandbox';
import {IGetStatusMessage} from '@/pages/imei-check-service/types/get-status-message';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import {CommonSandbox} from '@/sandbox/common.sandbox';
import {Router} from '@/core/mixin/router';

@Options({
    name: 'imei-check-service-component',
    components: {
        ServiceNameComponent,
        ServiceSubtitleComponent,
    }
})

export default class ImeiCheckServiceComponent extends mixins(Router)  {
    public service = 1071;
    private sandbox = container.resolve(ImeiCheckServiceSandbox);
    public sandboxCommon: CommonSandbox = container.resolve(CommonSandbox);
    private store = store;
    public locale$: any = locale;
    public locale: any = null;
    public localeElements: any = null;
    public imeiError: boolean = false;
    public imei: string = '';
    public $refs!: {
        modal: {
            openModal: () => void;
            closeModal: () => void
        },
    };

    get getStatusMessage(): IGetStatusMessage {
        return this.store.getters._getStatusMessage;
    }

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }
    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.registrationMobile) {
                this.locale = res.registrationMobile;
                this.localeElements = res.elements;
            }
        });
    }
    public mounted() {
        this.getServiceData();
    }

    public imeiCheck() {
        this.imei = this.imei.replace(/[^0-9]+/g, '').slice(0, 15);
        if (this.imeiError) {
            this.imeiError = false;
        }
    }

    public getImeiStatus() {
        this.imeiValidation();
        if (!this.imeiError) {
            this.sandbox.getStatusMessage({imei:this.imei, token:localStorage.getItem('mainToken')});
            this.$refs.modal.openModal();
        }
    }

    public imeiValidation() {
        if (this.imei.length !== 15) {
            this.imeiError = true;
        } else {
            this.imeiError = false;
        }
    }

    public closeModal() {
        this.$refs.modal.closeModal();
    }


}
