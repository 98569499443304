<style src="./imei-check-service.component.scss" lang="scss"></style>

<template>
  <div class="imei-check-service mb-100">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
        </div>
        <div class="col-12">
          <section class="mt-20">
            <v-card class="h-container">
              <div class="row">
                <div class="col-12">
                  <div class="imei-check-service__header">
<!--                    <services-subtitle-component>-->
<!--                      Xidməti seçin-->
<!--                    </services-subtitle-component>-->
<!--                    <span>{{ locale.description }}</span>-->
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="imei-check-service__check mt-20">
                    <form class="form" @submit.prevent="getImeiStatus">
                      <div class="form__group">
                        <label
                            :class="{'v-label--error':imeiError}"
                            for="imeiCheck" class="v-label">IMEI</label>
                        <div class="form__wrapper d-flex">
                          <input
                              @input="imeiCheck"
                              v-model="imei"
                              placeholder="353556094510108"
                              :class="{'v-input--error':imeiError}"
                              type="text" class="v-input" id="imeiCheck">
                          <div class="form__group-info  d-sm-none d-block">
                            {{ locale.enterCode }}
                          </div>
                          <v-divider class="d-block d-md-none"/>
                          <v-btn color="dark">
                            {{ localeElements.check }}
                          </v-btn>
                        </div>
                      </div>
                      <div class="form__group-info mt-12 ml-20 d-sm-block d-none">
                        {{ locale.enterCode }}
                      </div>
                    </form>
                  </div>
                </div>
<!--                <div class="col-12 text-center">-->
<!--                  <v-divider class="mt-24 mb-40"/>-->
<!--                  <v-btn color="dark" class="mb-40" @click="getImeiStatus">-->
<!--                    {{ localeElements.check }}-->
<!--                  </v-btn>-->
<!--                </div>-->
              </div>
            </v-card>
          </section>
        </div>
      </div>
    </div>
    <v-modal
        title="Məlumat"
        ref="modal"
    >
      <template v-slot:body>
        <img src="@/assets/images/icons/modal-information.svg" alt="" class="mt-12 mb-44">
        <div class="imei-check-service__modal-description mb-28">
          {{ getStatusMessage.text }}
        </div>
      </template>
      <template v-slot:footer>
        <div class="text-center">
          <v-btn class="py-10 px-80 modal-btn"
                 color="black"
                 @click="closeModal">{{ localeElements.close }}
          </v-btn>
        </div>
      </template>
    </v-modal>
  </div>

</template>

<script src="./imei-check-service.component.ts"></script>
