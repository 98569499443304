import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class ImeiCheckServiceSandbox {
    private store = store;

    constructor() {
    }

    getStatusMessage(payload: any): void {
        this.store.dispatch('getStatusMessage', payload);
    }

    getCommonService(payload: number): any {
        return this.store.dispatch('getServiceCommon', payload);
    }

    getCommonServiceHeader(payload: number): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }

}
